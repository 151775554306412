.reg-form input {
  border-color: var(--brand-color);
}
.reg-form input::placeholder {
  color: #d8d8d8;
}
.reg-form select {
  border-color: var(--brand-color);
}
.reg-form select:focus {
  border-color: var(--brand-color);
}
.reg-form textarea {
  border-color: var(--brand-color);
}
.reg-form textarea::placeholder {
  color: #d8d8d8;
}
.order-card img {
  width: 200px;
}
