.top-bg-brand {
  background-color: var(--brand-color);
  height: 12px;
  width: 100%;
}
.logo img {
  height: 90px;
}
.logo a {
  text-decoration: none;
}
.logo p {
  font-weight: 900;
  font-size: 20px;
  color: #414141;
}
.connect svg {
  font-size: 35px;
}
.connect p {
  font-size: 15px;
}
.mobile-nav-icon {
  display: none !important;
  cursor: pointer;
}
.mobile-nav-view {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.mobile-nav-view a,
.connect a {
  color: #000;
  text-decoration: none;
}
@media only screen and (max-width: 1000px) {
  .header-mobile {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .header-mobile img {
    margin-left: auto;
  }
}
/* .font-mobile,
.mobile-nav-contact {
  font-family: "Roboto", serif !important;
} */
@media only screen and (max-width: 600px) {
  .mobile-nav-contact {
    display: none;
  }
  .mobile-nav-icon {
    display: block !important;
  }
}
.bg-brand {
  background-color: #000;
}
