@import url(./import.css);

body {
  font-family: "AdorshoLipi", sans-serif, "Roboto", serif !important;
}
a {
  text-decoration: none;
  color: #000;
}
.coursor {
  cursor: pointer;
}
