.pakage img {
  max-height: 500px;
}
.final-price {
  color: rgb(255, 81, 0);
  font-weight: 900;
  font-size: 55px;
}
.price {
  color: rgb(255, 81, 0);
  font-weight: 900;
  font-size: 20px;
  text-decoration: line-through;
}
.free {
  font-size: 25px;
  color: rgb(255, 81, 0);
  font-weight: 800;
  margin: 0 10px;
  transform: rotate(10deg);
  display: inline-block;
}
.orderPrice {
  color: rgb(255, 81, 0);
  font-weight: 900;
  font-size: 20px;
}
